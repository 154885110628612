import {
  Alert,
  ColumnLayout,
  FormField,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useEffect } from "react";
import { AppStreamSdkProps } from "../../../../types/Toolbar";
import { useToolbarPreferenceStore } from "../../../../hooks/useToolbarPreferenceStore";
import { Trans, useTranslation } from "react-i18next";
import { isBrowserPermissionGranted } from "../../../../utils/toolbarItemUtils";
import { is } from "immer/dist/internal";

const MicrophoneSelector = (): JSX.Element => {
  const { t } = useTranslation();
  let isMicrophonePermissionAllowed;
  useEffect(() => {
    (async () => {
      isMicrophonePermissionAllowed = (await isBrowserPermissionGranted(
        "microphone"
      ))
        ? true
        : false;
    })();
  }, []);

  const microphonePermissionNotAllowedNotification = (
    <Alert
      statusIconAriaLabel="Warning"
      type="warning"
      data-testId="microphonePermissionAlert"
      header={t(
        "toolbar.item.preference.content.tab.audioVideo.microphone.permissionAlert.header"
      )}
    >
      <Trans
        i18nKey={
          "toolbar.item.preference.content.tab.audioVideo.microphone.permissionAlert.content"
        }
      />
    </Alert>
  );

  // TODO: Replace values with actual device options once we have access to the SDK functions
  const dummyOptions = [
    { label: "Option 1", value: "1" },
    { label: "Option 2", value: "2" },
  ];
  const [selectedOption, setSelectedOption] = React.useState({
    label: dummyOptions[0].label,
    value: dummyOptions[0].value,
  });

  return (
    <FormField
      label={t(
        "toolbar.item.preference.content.tab.audioVideo.microphone.label"
      )}
      description={t(
        "toolbar.item.preference.content.tab.audioVideo.microphone.description"
      )}
      data-testid="microphoneFormField"
    >
      {isMicrophonePermissionAllowed ? (
        <Select
          selectedOption={selectedOption}
          onChange={({ detail }) =>
            setSelectedOption({
              label: detail.selectedOption.label,
              value: detail.selectedOption.value,
            })
          }
          options={dummyOptions}
          data-testid="microphoneSelectMenu"
        />
      ) : (
        microphonePermissionNotAllowedNotification
      )}
    </FormField>
  );
};

const CameraSelector = (): JSX.Element => {
  const { t } = useTranslation();
  let isCameraPermissionAllowed;
  useEffect(() => {
    (async () => {
      isCameraPermissionAllowed = (await isBrowserPermissionGranted("camera"))
        ? true
        : false;
    })();
  }, []);
  const cameraPermissionNotAllowedNotification = (
    <Alert
      statusIconAriaLabel="Warning"
      type="warning"
      data-testId="cameraPermissionAlert"
      header={t(
        "toolbar.item.preference.content.tab.audioVideo.camera.permissionAlert.header"
      )}
    >
      <Trans
        i18nKey={
          "toolbar.item.preference.content.tab.audioVideo.camera.permissionAlert.content"
        }
      />
    </Alert>
  );

  // TODO: Replace values with actual device options once we have access to the SDK functions
  const dummyOptions = [
    { label: "Option 1", value: "1" },
    { label: "Option 2", value: "2" },
  ];
  const [selectedOption, setSelectedOption] = React.useState({
    label: dummyOptions[0].label,
    value: dummyOptions[0].value,
  });

  return (
    <FormField
      label={t("toolbar.item.preference.content.tab.audioVideo.camera.label")}
      description={t(
        "toolbar.item.preference.content.tab.audioVideo.camera.description"
      )}
      data-testid="cameraFormField"
    >
      {isCameraPermissionAllowed ? (
        <Select
          selectedOption={selectedOption}
          onChange={({ detail }) =>
            setSelectedOption({
              label: detail.selectedOption.label,
              value: detail.selectedOption.value,
            })
          }
          options={dummyOptions}
          data-testid="cameraSelectMenu"
        />
      ) : (
        cameraPermissionNotAllowedNotification
      )}
    </FormField>
  );
};

/**
 * Preferences - Audio & Video tab content
 */
export const AudioVideoTabContent = ({
  appStreamSdk,
}: AppStreamSdkProps): JSX.Element => {
  return (
    <ColumnLayout columns={2} data-testId="audio-video-tab-content">
      <SpaceBetween direction="vertical" size="m">
        <MicrophoneSelector data-testId="microphone-selector-audioVideoTab" />
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="m">
        <CameraSelector data-testId="camera-selector-audioVideoTab" />
      </SpaceBetween>
    </ColumnLayout>
  );
};
